import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'

import HeadtoHead from '../components/HeadtoHead3'

import HeadTags from '../components/Headtags22'
import SnackAd2 from '../components/SnackAd2'
import SnackAd from '../components/SnackAd'
import {Container,Row} from "react-bootstrap"
import * as queryString from "query-string"

// import Amplify from 'aws-amplify';
// import config from '../aws-exports';

// Amplify.configure(config);

// Get the location object that is implicitly passed as props
// for every page in the `pages` folder
// const Index = ({ location }) => {
//   console.log(location); // inspect location for yourself
//
//   // query-string parses the parameters that are contained in the location object
//   const { parameter1, parameter2 } = queryString.parse(location.search);
//
//   console.log(parameter1);
//   console.log(parameter2);
  // ...


const CpfplVsPage = ({ location }) => {
console.log(location);
var { lgcode,h_man_code,a_man_code} = queryString.parse(location.search);
console.log(lgcode);
console.log(h_man_code);
if(h_man_code==null){
  h_man_code=0;
}
if(a_man_code==null){
  a_man_code=0;
}
//   console.log(parameter2);
return (
  <>
    <HeadTags />
    <div style ={{position:"relative", minHeight:"100vh"}}>
    <Row>
        <SnackAd2 ad_type = "leader" ad_num = {1}/>
    </Row>
      <Header />
      <HeadtoHead lgCode = {lgcode} h_man_code={h_man_code} a_man_code= {a_man_code} />
      <Container fluid>
        <Row>
          <SnackAd ad_type = "leader" ad_num = {4}/>
        </Row>
      </Container>
      <Footer />
    </div>
  </>
)
}

export default CpfplVsPage
